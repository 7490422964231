<template>
  <el-dialog
      :title="'修改'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item label="数据项id" prop="id">
        <el-input v-model="dataForm.id" placeholder="数据项id" readonly="true"></el-input>
      </el-form-item>
      <el-form-item label="数据项名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="数据项名称"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="dataForm.remark" placeholder="备注" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class=" dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "updateMeasurePoint",
  data() {
    return {
      visible: false,
      dataForm: {
        id: undefined,
        name: undefined,
        remark: undefined
      },

      dataRule: {
        id: [
          { required: true, message: 'id不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '描述不能为空', trigger: 'blur' }
        ]
      },
    }
  },
  methods: {
    init (row) {
      this.dataForm.id = row.id
      this.dataForm.name = row.name
      this.dataForm.remark = row.remark
      this.visible = true
    },
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$confirm(`确定进行修改[${this.dataForm.name}]操作?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.postRequest('/measurePointManage/updateMeasurePointInfoById', this.dataForm).then((resp) => {
              if(resp) {
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          }).catch(() => {})
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
